<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >


      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex "
          >
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="search"
                class="mr-1"
            >
              <span class="align-middle">查询</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                @click="selectAllRows"
                class="mr-1"
            >
              <span class="align-middle">全部勾选</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="clearSelected"
            >
              <span class="align-middle">清空勾选</span>
            </b-button>
          </b-col>

        </b-row>

      </div>
      <ValidationObserver ref="observerItemList">
        <b-table
            sticky-header
            noCollapse
            :tbody-tr-class="rowClass"
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            selectable
            selected-variant="success"
            select-mode="multi"
            @row-selected="onRowSelected"
        >


        </b-table>
      </ValidationObserver>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import purchaseorderitemUseList from './purchaseorderitemModifyUseList'
import productStore from '../product/productStore'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {useToast} from 'vue-toastification/composition'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import { add, divide, multiply, subtract } from '@core/utils/math/utils'
import purchaseordermodifyStore from "@/views/apps/purchaseordermodify/purchaseordermodifyStore";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProductSelect,
    AttachmentUpload
  },
  data() {
    return {
      selectedItem: {},
      dataList: [],
      costTaxReadonly:false,
      excelSqlId: '',
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') { return }

      // if (item.ext.inbound > 0) {
      //   return 'table-warning'
      // }
    },
    isGiftChange(item, value,index) {
      item.is_gift = parseInt(value)
      if (item.is_gift === 1){
        item.product_cost_tax = 0
        this.calTotal(index)
      }
    },
    validateRow : async function (index) {
      let refs = this.$refs.observerItemList.refs
      let validArr = []
      for (const key in refs) {
        if (refs.hasOwnProperty(key) && key.includes(index)) {
          let validate = await refs[key].validate()
          if (!validate['valid']) {
            validArr.push(key)
          }
        }
      }
      return validArr.length === 0
    },
    async validateAll() {
      let count = 0
      let items = this.dataList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`observer_qty_${i}`].validate() && await this.$refs[`observer_cost_${i}`].validate()) {
          count++
        }
      }
      return count === items.length
    },
    changeSelect(key, event, index) {
      this.dataList[index][key] = event == null ? undefined : event.value
      this.calTotal(index)
      // this.purchaseorder[key] = event == null ? undefined :  event.value
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
  },
  props: {
    purchaseordermodify: {
      type: Object,
      default: {}
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('product')) store.registerModule('product', productStore)
    if (!store.hasModule('purchaseordermodify')) store.registerModule('purchaseordermodify', purchaseordermodifyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('product')) store.unregisterModule('product')
      if (store.hasModule('purchaseordermodify')) store.unregisterModule('purchaseordermodify')
    })

    const onSelectProducts = function () {
      let selected = this.$refs.myProductSelect.getSelected();
    }

    const search = function () {
      if (props.purchaseordermodify.product_id==undefined||props.purchaseordermodify.product_id==null){
        toast.error("商品必填!")
        return ;
      }
      if (props.purchaseordermodify.product_cost_tax_modify==undefined||props.purchaseordermodify.product_cost_tax_modify==null){
        toast.error("调整含税单价必填!")
        return ;
      }
      this.isSearch = 1
      this.refetchData()
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      isSearch,

      // UI
    } = purchaseorderitemUseList({
      purchaseordermodify:props.purchaseordermodify
    })


    return {
      onSelectProducts,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      isSearch,

      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      search,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-card
      class="purchaseordermodify-edit-wrapper"
  >
    <!-- form -->
    <ValidationObserver ref="observer">
      <b-form id="purchaseordermodifyForm" class="edit-form mt-2">
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">基本信息</div>
          </b-col>
          <b-col md="3">
            <xy-input-button
                label="商品"
                type="input"
                v-on:change="fromChildren($event,['name','product_id'])"
                :params="['name','product_id']"
                :value="purchaseordermodify.name"
                modalName="商品"
                placeholder="点击选择商品"
            >
            </xy-input-button>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调整含税单价"
                label-for="product_cost_tax_modify"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="product_cost_tax_modify"
                  size="sm"
                  type="number"
                  v-model="purchaseordermodify.product_cost_tax_modify"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="编号"
                label-for="modify_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="modify_no"
                  size="sm"
                  v-model="purchaseordermodify.modify_no"
                  readonly
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <div class="inner-card-title">查询条件</div>
          </b-col>
          <b-col md="3">
            <xy-input-button
                label="供应商"
                type="input"
                v-on:change="fromChildren($event,['supplier_name','supplier_id'])"
                :params="['supplier_name','supplier_id']"
                :value="purchaseordermodify.supplier_name"
                modalName="供应商"
                placeholder="点击选择供应商"
            >
            </xy-input-button>
          </b-col>
          <b-col md="3">
            <xy-input-button label="订单开始日期"
                             type="data"
                             v-on:change="fromChildren($event,['order_create_starttime'])"
                             :params="['order_create_starttime']"
                             :value="purchaseordermodify.order_create_starttime"
            >
            </xy-input-button>
          </b-col>
          <b-col md="3">
            <xy-input-button label="订单结束日期"
                             type="data"
                             v-on:change="fromChildren($event,['order_create_endtime'])"
                             :params="['order_create_endtime']"
                             :value="purchaseordermodify.order_create_endtime"
            >
            </xy-input-button>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="订单含税单价起始"
                label-for="product_cost_tax_start"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="product_cost_tax_start"
                  size="sm"
                  type="number"
                  v-model="purchaseordermodify.product_cost_tax_start"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="订单含税单价结束"
                label-for="product_cost_tax_end"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="product_cost_tax_end"
                  size="sm"
                  type="number"
                  v-model="purchaseordermodify.product_cost_tax_end"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <div class="inner-card-title">列表信息</div>
          </b-col>

          <b-col md="12">
            <purchase-order-modify-item-list
                :purchaseordermodify="purchaseordermodify"
                v-if="itemListShowFlag"
                ref="itemList"
            >

            </purchase-order-modify-item-list>
          </b-col>

          <b-col md="12">
            <div class="inner-card-title">其他信息</div>
          </b-col>


          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="备注"
                label-for="memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="memo"
                  size="sm"
                  v-model="purchaseordermodify.memo"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="相关附件ids"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload v-if="purchaseordermodify.loaded" :theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="purchaseordermodify.attachments"
                                 :object_type="'sales_order'"
                                 :object_id="purchaseordermodify.modify_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                @click="cancel"
            >
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchaseordermodifyStore from './purchaseordermodifyStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import PurchaseOrderModifyList from "@/views/apps/purchaseordermodify/PurchaseOrderModifyList";
import PurchaseOrderModifyItemList from "@/views/apps/purchaseordermodify/PurchaseOrderModifyItemList";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    AttachmentUpload,
    XyInputButton,
    PurchaseOrderModifyItemList,
    PurchaseOrderModifyList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchaseordermodify: ref({}),
      itemListShowFlag: false,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchaseordermodify')) store.registerModule('purchaseordermodify', purchaseordermodifyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseordermodify')) store.unregisterModule('purchaseordermodify')
    })

    const edit = function () {
      store.dispatch('purchaseordermodify/edit', {id: this.id}).then(res => {
        this.purchaseordermodify = res.data.data
        this.itemListShowFlag = true
        this.purchaseordermodify.order_create_starttime = toTime(this.purchaseordermodify.order_create_starttime)
        this.purchaseordermodify.order_create_endtime = toTime(this.purchaseordermodify.order_create_endtime)
      })
    }

    const view = function () {
      store.dispatch('purchaseordermodify/view', {id: this.id}).then(res => {
        this.purchaseordermodify = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.purchaseordermodify.product_id==undefined||this.purchaseordermodify.product_id==null){
        toast.error("商品必填!")
        return ;
      }
      if (this.purchaseordermodify.product_cost_tax_modify==undefined||this.purchaseordermodify.product_cost_tax_modify==null){
        toast.error("调整含税单价必填!")
        return ;
      }
      let itemData = this.$refs.itemList.selected==undefined?[]:this.$refs.itemList.selected

      this.purchaseordermodify.order_create_starttime = this.purchaseordermodify.order_create_starttime==null?null:second(this.purchaseordermodify.order_create_starttime)
      this.purchaseordermodify.order_create_endtime = this.purchaseordermodify.order_create_endtime==null?null:second(this.purchaseordermodify.order_create_endtime)
      store.dispatch('purchaseordermodify/save', this.purchaseordermodify).then(res => {
        if (res.data.code==0){
          const itemIdsArray = []
          for (let i = 0; i < itemData.length; i++) {
            itemIdsArray.push(itemData[i].item_id)
          }
          const itemIds = itemIdsArray.join(",")
          store.dispatch('purchaseordermodify/saveItem', {itemIds:itemIds,id:this.purchaseordermodify.id}).then(res => {
            if (res.data.code==0){
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-purchaseordermodify-list'});
            }else {
              toast.error(res.data.data)
            }
          })
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.purchaseordermodify[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const onUploaded = function (id, attachment, result) {
      this.purchaseordermodify[id] = result
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {second, toTime} from "@core/utils/filter";

export default function purchaseorderitemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'order_no', label: '订单编号'},
    { key: 'applier_name', label: '申请人'},
    { key: 'qty', label: '订单数量'},
    { key: 'product_cost_tax', label: '订单单价'},
    { key: 'product_cost_tax_modify', label: '调整单价'},
    { key: 'after_product_cost_tax_modify', label: '调整后单价'},
    { key: 'product_cost_tax_subtotal', label: '调整前订单金额'},
    { key: 'change_product_cost_tax_subtotal', label: '调整金额'},
    { key: 'after_product_cost_tax_subtotal', label: '调整后金额'},
    { key: 'create_time', label: '订单日期'},
    { key: 'supplier_name', label: '供应商'},
    { key: 'company_name', label: '主体名称'},
    { key: 'status', label: '订单状态' },
  ]

  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)
  const isSearch = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    // config.purchaseordermodify.order_create_starttime = config.purchaseordermodify.order_create_starttime==null?null:second(config.purchaseordermodify.order_create_starttime)
    // config.purchaseordermodify.order_create_endtime = config.purchaseordermodify.order_create_endtime==null?null:second(config.purchaseordermodify.order_create_endtime)
    if (isSearch.value===0){
      store
          .dispatch('purchaseordermodify/getItem', config.purchaseordermodify)
          .then(response => {
            const data = response.data.data
            // const list = data.ext.list
            // listTotals.value = data.ext.totals
            callback(data)
          })
          .catch((e) => {
            toast({
              component: ToastificationContent,
              props: {
                title: '采购单明细列表获取错误',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }else {
      store
          .dispatch('purchaseordermodify/searchItem', config.purchaseordermodify)
          .then(response => {
            const data = response.data.data
            // const list = data.ext.list
            // listTotals.value = data.ext.totals
            callback(data)
          })
          .catch((e) => {
            toast({
              component: ToastificationContent,
              props: {
                title: '采购单明细列表获取错误',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    isSearch,
    // Extra Filters
  }
}
